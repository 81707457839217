<template>
  <header_top class="header_top"></header_top>
  <router-view style="min-height: 60vh"> </router-view>
  <footer_component/>
  <copyright_footer></copyright_footer>
</template>

<style lang="scss">
// Fonts imports
@import url('https://fonts.googleapis.com/css2?family=Maitree:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Maitree:wght@200;300;400;500;600;700&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
// Style import
@import "assets/base.scss";

#app {
 /* font-family: Avenir, Helvetica, Arial, sans-serif;*/
  font-family: "Rubik", serif;
  font-optical-sizing: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  overflow-x: hidden;
}

* {
  padding: 0;
  margin: 0;
}

.container {
  //width: 80%;
  margin-left: 50%;
  transform: translateX(-50%);
  display: flex;
  height: 100%;
  max-width: 1200px;
}

@media (min-width: 1200px) {
  .container {
    width: 80%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .container {
    width: 95%;
  }
}

@media screen and (max-width: 1199px) {
  .container {
    width: 95%;
  }
}

.flex_div {
  display: flex;
}

.header_top {
  top: 0;
  position: sticky;
}

::-moz-selection { /* Code for Firefox */
  color: $text_color;
  background: $accent_color;
}

::selection {
  color: $text_color;
  background: $accent_color;
}
</style>
<script>
import header_top from "@/components/header_top.vue";
import footer_component from "@/components/footer.vue";
import copyright_footer from "@/components/svg/copyright_footer.vue";

export default {
  name: "app_view",
  components: {
    copyright_footer,
    footer_component,
    header_top
  }
}


</script>