<script>
import axios from "axios"
import Product_card from "@/components/catalog/product_card.vue";
import Animated_fire from "@/components/svg/animated_fire.vue";


export default {
  name: "catalog_view",
  components: {Animated_fire, Product_card},

  data() {
    return {
      products: [],
      is_loading: true,
      fetch_error: false,
    }
  },

  created() {
    this.is_loading = true
    this.getFilteredProducts(this.$route.params.slug)
  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    '$route.params.slug': function (newSlug, oldSlug) {
      this.is_loading = true
      this.getFilteredProducts(newSlug)
      this.is_loading = false
      // Аналитика при изменении категории
      this.sendAnalytics('view_category', newSlug || 'all');
    }
  },
  methods: {
    async getFilteredProducts(slug) {
      this.is_loading = true;
      let base_url = `${process.env.VUE_APP_API_URL}products/`;
      if (slug !== undefined && slug !== "") {
        base_url = `${base_url}?category=${slug}`;
      }
      try {
        const response = await axios.get(base_url);
        this.products = response.data;
      } catch (error) {
        this.fetch_error = true;
      } finally {
        this.is_loading = false;
      }
    },

    goto(productId){
      // Аналитика клика по продукту
      const product = this.products.find(p => p.id === productId);
      this.sendAnalytics('click_product', product ? product.name : `ID: ${productId}`);

      this.$router.push({ name: 'product', params: { id: productId } });
    },

    // Метод отправки аналитики
    sendAnalytics(action, label) {
      fetch('/api/analytics', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          action,       // Тип действия (например, 'click_product', 'view_category')
          label,        // Метка (например, название категории или продукта)
          userId: 1,    // ID пользователя, если доступен
          timestamp: new Date() // Время события
        })
      })
          .then(response => console.log('Аналитика отправлена:', response))
          .catch(error => console.error('Ошибка отправки аналитики:', error));
    },
    goBackHandle(){
      this.$router.push({ name: 'home'});
    },
  },
  computed: {
    title() {
      if (!this.$route.params.slug || this.products.length === 0) {
        return "Каталог"
      } else {
        return this.products[0].category.name
      }
    }
  }
}
</script>

<template>
  <div class="container">
    <div class="loader" v-if="this.is_loading"><Animated_fire class="animated-fire"></Animated_fire></div>
    <div v-else>
      <div class="title">
        <!-- Контроллер выхода на предыдущую страничку -->
        <button @click="goBackHandle" class="goBack-control">←</button>
        <h3 class="product-title">{{ title }}</h3>
      </div>

      <div class="products-wrapper">
        <div class="cards_wrapper">
          <div class="products" v-for="product in products" :key="product.id">
            <Product_card @click.prevent="goto(product.id)" :product_data="product"></Product_card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/base";
.container {
  flex-direction: column;
}

.title {
  padding: 20px 0;
  font-size: 26px;
  font-weight: 600;
  display:flex;
  flex-direction: row;
  align-items: center;
}

.products-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.cards_wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, 300px); /* Фиксированная ширина колонок */
  gap: 20px; /* Отступ между колонками */
  max-width: 940px;
  width: 100%;
}

@media screen and (max-width: 990px) {
  .cards_wrapper {
    justify-content: center;
  }
}


.loader{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
}

.animated-fire{
  transform: scale(150%);
}

.goBack-control {
  border: none;
  font-size: 35px;
  cursor: pointer;
  margin-right: 30px;
  color: $dg2;
  background-color: transparent;
}
.goBack-control:hover {
  color: $accent_color;
}
.product-title {
  margin-top: 15px;
  font-size: 35px;
  font-weight: bold;
}
</style>