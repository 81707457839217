<script>
export default {
  name: "catalog_items",
  data() {
    return {
      data: [
        {"title": "Бойлеры <br> (водонагреватели)", "icon": require("@/assets/svg/gas_boiler.svg"), "link": "/catalog/boiler"},
        {"title": "Пакетные решения <br> (Котел + бойлер)", "icon": require("@/assets/svg/packet_solution.svg"), "link": "/catalog/all-included"},
      ]
    }
  },
  methods:{
    // Отправка аналитики
    sendAnalytics(action, label) {
      fetch('/api/analytics', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          action: action,  // Тип действия
          label: label,    // Метка
          timestamp: new Date() // Текущее время
        })
      })
          .then(response => console.log('Аналитика отправлена:', response))
          .catch(error => console.error('Ошибка отправки аналитики:', error));
    },

    goto(item){
      this.sendAnalytics('click', item.title.replace(/<br>/g, ' ')); // Убираем HTML-теги из заголовка
      this.$router.push(item.link)
    }
  }
}
</script>

<template>
  <div class="container">
    <div v-for="(item, index) in data" class="catalog_item" :key="index" v-on:click="goto(item)">
      <img :src="item.icon" :alt="item.title">
      <p class="title" v-html="item.title"></p>
    </div>
  </div>
</template>


<style scoped lang="scss">
@import "@/assets/base.scss";

.container {
  gap: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.catalog_item {
  background-color: $dg2;
  display: flex;
  flex: 1;
  flex-direction: column;
  color: $text_color;
  font-size: 20px;
  text-align: center;
  padding: 20px;
  border-radius: 10px;

  flex-basis: calc(50% - 15px); /* Два элемента в строке, с учетом gap */
  max-width: calc(50% - 15px);
  box-sizing: border-box;

  img {
    height: 100px;
  }

  .title {
    margin-top: 20px;
  }

  @media (max-width: 550px) {
    flex-basis: calc(100% - 15px); /* Один элемент на строку */
    max-width: calc(100% - 15px);
  }
}

@media (hover: hover) {
  /* when hover is supported */
  .catalog_item:hover{
    cursor: pointer;
  }
}
</style>