<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "logotype",
  data() {
    return {
      a1Src: require('@/assets/svg/a1.svg'), // путь к картинке
      mtsSrc: require('@/assets/svg/mts.svg'), // путь к картинке
      lifeLightSrc: require('@/assets/svg/lifeLightened.svg'), // путь к картинке
    };
  }
}
</script>

<template>
  <div>

    <div class="logo_wrapper">


     <span class="header_top__brand_name">
            7797
     </span>


      <div class="header_top__partners">
        <a href="tel:+375447837777">
          <img :src="a1Src" alt="Logotype" class="logo-image">
        </a>

        <a href="tel:+375297837777">
        <img :src="mtsSrc" alt="Logotype" class="logo-image">
        </a>

        <a href="tel:+375257837777">
            <img :src="lifeLightSrc" alt="Logotype" class="logo-image-life">
        </a>

      </div>

    </div>

  </div>
</template>

<style scoped lang="scss">
@import "@/assets/base";

.logo_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

}

.lifeLogo-wrapper{
  background-color: white;
}

.logo-image-life{
  color:cornflowerblue;
}
.header_top__brand_name {
  font-family: "Rubik", serif;
  font-weight: 700;
  font-style: normal;
  font-size: 70px;
  letter-spacing: 3px;
  color: $text_color;
  margin: 0 40px;
}

.header_top__brand_name:nth-child(1) {
  margin-right: 10px;
}

.header_top__partners {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.logo-image {
  width: 50px;
  height: 20px;
}
</style>