<template>

  <div class="container">
    <Carousel v-if="!isLoading" :wrap-around="true" :autoplay="3000"> //
      <Slide v-for="slide in data" :key="slide">
        <div class="carousel__item" :style="{ backgroundImage: `url(${mediaUrl(slide.media_file.file)})` }">
          <p v-if="slide.title" class="slide_title">{{slide.title}}</p>
          <div class="slide_content" v-html="slide.description"></div>
          <a v-if="slide.action_buttons.length >= 1" :href="slide.action_buttons[0]?.link">
            <button class="slide_button" v-if="true">{{ slide.action_buttons[0]?.title }}
            </button></a>
        </div>
      </Slide>

      <template #addons>
        <Navigation/>
        <Pagination/>
      </template>
    </Carousel>
  </div>
</template>

<script>
import {defineComponent} from 'vue'
import {Carousel, Navigation, Pagination, Slide} from 'vue3-carousel'
import axios from "axios";

import 'vue3-carousel/dist/carousel.css'

export default defineComponent({
  name: 'services_carousel',
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  async created(){
    await axios.get(`${process.env.VUE_APP_API_URL}slides/`).then(response => (this.data = response.data))
    this.isLoading = false
  },
  data(){
    return {
      isLoading: true,
      data: null,
    }
  },
  methods: {
    mediaUrl(path) {
      /*return process.env.VUE_APP_API_URL + path*/
      if (path !== "" && path !== undefined) {
        const width = window.innerWidth;  // Ширина экрана
        const height = window.innerHeight;  // Высота экрана
        const baseUrl = process.env.VUE_APP_API_URL + path;  // Базовый URL
        return `${baseUrl}?width=${width}&height=${height}`;  // Возвращаем URL с параметрами
      }
      return null;  // Возвращаем null, если путь не задан
    }
  }
})
</script>

<style scoped>
.carousel{
  width: 100%;
}

.carousel__item {
  height: 60vh;
  width: 100%;
  color: var(--vc-nav-color);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  background-position: center;
  background-size: cover;
  padding-left: 80px;
  .slide_title{
    font-size: 1.7vw;
  }
  .slide_content{
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    text-align: start;
    max-width: 60%;
    padding: 10px;
    font-size: 1.5vw;
    max-font-size: 16px;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.16);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.3);
  }
  @supports (backdrop-filter: blur(5px)) {
      .slide_content {
          background: rgba(255, 255, 255, 0.2); /* более прозрачный фон */
          backdrop-filter: blur(5px); /* применяем размытие */
          -webkit-backdrop-filter: blur(5px);
      }
  }
  @media (max-width: 768px) {
    .slide_content {
      max-width: 90%;
      font-size: 25px;
    }
  }
  .slide_button{
    position: absolute;
    right: 80px;
    bottom: 40px;
    padding: 10px 30px;
    box-sizing: border-box;
    border: none;
    border-radius: 5px;
    font-size: 20px;
    background: linear-gradient(180deg,  #68749F 0%, #BE3F43 100%);
    color: white; /* Цвет текста */
    transition: background 0.3s ease;
  }
  .slide_button:hover {
    background: linear-gradient(180deg, #BE3F43 0%, #dca364 100%);
  }
}
@media (max-width: 768px) {
    .carousel__item {
      padding-left: unset;
      align-items: center;
      font-size: 25px;

      .slide_button{
        position: absolute;
        bottom: 40px;
      }
    }
  }

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}
</style>