<script>
export default {
  name: "product_card",
  props: ["product_data"],
  data() {
    return {
      currentMediaIndex: 0, // Индекс текущего медиа
      status_mapping: {
        "available": "green",
        "temporary_unavailable": "orange",
        "unavailable": "red",
      },
    };
  },

  computed: {
    // Текущее отображаемое медиа
    currentMedia() {
      return this.product_data.media_files[this.currentMediaIndex];
    },
  },

  methods: {
    // Переключение на следующее медиа
    nextMedia() {
      if (this.currentMediaIndex < this.product_data.media_files.length - 1) {
        this.currentMediaIndex++;
      } else {
        this.currentMediaIndex = 0; // Вернуться к первому
      }
    },
    // Переключение на предыдущее медиа
    prevMedia() {
      if (this.currentMediaIndex > 0) {
        this.currentMediaIndex--;
      } else {
        this.currentMediaIndex = this.product_data.media_files.length - 1; // Вернуться к последнему
      }
    },

    urlForMedia() {
      const width = window.innerWidth; // Ширина экрана
      const height = window.innerHeight; // Высота экрана
      const baseUrl = process.env.VUE_APP_API_URL + this.currentMedia.file; // Базовый URL
      // URL с параметрами
      return `${baseUrl}?width=${width}&height=${height}`;
    },
    setMedia(index) {
      this.currentMediaIndex = index;
    },

    truncateText(text, maxLength) {
      if (text.trim().length > maxLength) {
        return text.slice(0, maxLength) + '...';
      }
      return text;
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="media_area">

      <div class="media" v-if="currentMedia">
        <img v-if="currentMedia.media_type === 'image'" :src="urlForMedia()" alt="Product Image">
        <video v-else :src="urlForMedia()" controls autoplay muted></video>


      </div>
      <!-- Контроллеры переключения -->
      <button @click.stop="prevMedia" class="media-control left-control" v-if="product_data.media_files.length > 1">←
      </button>
      <button @click.stop="nextMedia" class="media-control right-control" v-if="product_data.media_files.length > 1">→
      </button>


      <div class="dots" v-if="product_data.media_files.length > 1">
          <span v-for="(media, index) in product_data.media_files"
                :key="index"
                :class="{ active: currentMediaIndex === index }"
                @click="setMedia(index)">
            •
          </span>
      </div>


    </div>

    <div>
      <p class="title">{{ truncateText(product_data.name, 35) }}</p>

      <p class="description">{{ truncateText(product_data.short_description, 30) }}</p>
      <div class="status" :style="{'color': status_mapping[product_data.status.value]}">
        ⦿
        {{
          product_data.status.label
        }}
      </div>
    </div>
  </div>

</template>

<style scoped lang="scss">
@import "@/assets/base";

.card {
  max-width: 300px;
  aspect-ratio: 4 / 6; // Соотношение сторон 4:6
  background-color: $extralightgrey_color;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 25px;
  border: none;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.12);
    transition: .15s;
  }
}

.media_area {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
  /*height: 400px;*/
  border-radius: 25px;
  overflow: hidden;
  position: relative;

  .media {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;

    img, video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; /* Центрирование обрезанного содержимого */
    }
  }
}

.title {
  font-weight: 500;
  font-size: 24px;
  padding: 10px 10px 5px 20px;
  color: $dg2;
  cursor: pointer;
}

.description {
  padding: 0 10px 0 20px;
  color: $lggrey;
  cursor: pointer;
}

.status {
  padding: 20px;
  cursor: pointer;
  //color: red;
}

.media-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  z-index: 10;
  width: 2em;
  height: 2em;
  font-size: 1.5em;

  &.left-control {
    left: 10px;
  }

  &.right-control {
    right: 10px;
  }

  &:hover { // todo add hover check
    background-color: rgba(0, 0, 0, 0.8);
  }
}

.dots {
  position: absolute;
  left: 120px;
  right: 0;
  top: 280px;

  &.dots span {
    cursor: pointer;
    font-size: 30px;
    margin: 0 3px;
    color: grey;
  }

  &.dots span.active {
    color: black;
    font-weight: bold;
  }

}


</style>